import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const WriteupTitle = styled(Typography)((props) => ({
  //
}));

// This needs to be a grid instead of a <p>, to prevent <p> from CMS to be nested under the WriteUpContent styled component.
// Will throw error on client side if encountering a nested <p> in a <p>.
export const WriteupContent = styled(Grid)((props) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  lineHeight: "20px",
  margin: 0,
}));

export const BackLinkGridStyled = styled(Grid)((props) => ({
  backgroundColor: Color.backgroundGrey,
  flex: "47%",

  [props.theme.breakpoints.down("lg")]: {
    flex: "100%",
    marginBottom: "16px",
  },

  // span for title
  span: {
    textTransform: "uppercase",
    fontWeight: "600",
    letterSpacing: "1.5px",
    lineHeight: "20px",
    fontSize: "10px",
    fontFamily: "Inter",
    color: Color.hiredlyPurple,
  },

  // hyperlink a tag for urls
  a: {
    fontSize: "12px",
    lineHeight: "20px",
    fontFamily: "Inter",
    textDecoration: "none",
    color: "rgba(0,0,0,0.87)",

    ":hover": {
      textDecoration: "underline",
    },
  },
}));

export const GridStyled = styled(Grid)((props) => ({
  backgroundColor: Color.backgroundGrey,
}));

export const OuterGridStyled = styled(Grid)((props) => ({
  backgroundColor: Color.backgroundGrey,
  padding: "0 80px 32px 80px",

  [props.theme.breakpoints.down("lg")]: {
    padding: "0 24px 24px 24px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0 16px 24px 16px",
  },
}));

export const LogoStyled = styled("img")((props) => ({
  width: "140px",
  height: "20px",

  [props.theme.breakpoints.down("tablet")]: {
    width: "98px",
    height: "14px",
  },
}));
