import { styled as styling } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { FontSize } from "../../../../assets/font";
import Color from "../../../../assets/colors";

export const ButtonContainer = styling("div")({
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
});

export const TitleContainer = styling("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.up("tablet")]: {
    justifyContent: "center",
  },
}));

export const ModalContainer = styling("div")(({ theme }) => ({
  padding: "30px 40px",

  [theme.breakpoints.down("mobileL")]: {
    padding: "24px",
  },

  [theme.breakpoints.up("tablet")]: {
    padding: "46px 30px 40px",
  },
}));

export const TextContainer = styling("div")(({ theme }) => ({
  textAlign: "center",

  [theme.breakpoints.up("tablet")]: {
    maxWidth: "70%",
    margin: "10px auto 10px",
  },
}));

export const Text = styling("span")((props) => ({
  fontSize:
    props.$text == "title"
      ? "18px"
      : props.$text == "message"
      ? "14px"
      : "12px",
  fontWeight: props.$text == "title" ? "700" : "400",
}));

export const DialogStyled = styling(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    maxWidth: "1280px",
  },
});

export const OutlinedButton = styling(Button)(({ theme }) => ({
  padding: "4px 0",
  fontSize: "12px",
  minWidth: "48%",
  fontWeight: FontSize.bold,
  borderRadius: "30px",
  background: Color.white,
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  boxShadow: "none",
  textTransform: "capitalize",

  "&:hover": {
    backgroundColor: Color.hiredlyPurple,
    color: Color.white,
  },
}));

export const SolidPurpleButton = styling(Button)(({ theme }) => ({
  padding: "4px 0",
  fontSize: "12px",
  minWidth: "48%",
  fontWeight: FontSize.bold,
  borderRadius: "30px",
  background: Color.hiredlyPurple,
  color: Color.white,
  boxShadow: "none",
  textTransform: "capitalize",

  "&:hover": {
    backgroundColor: Color.darkPurple,
  },
}));

export const HorizontalRuleStyled = styling("hr")(({ theme }) => ({
  border: `1px solid ${Color.lightGrey}`,

  [theme.breakpoints.up("tablet")]: {
    display: "none",
  },
}));
