import { assetDomain } from "../../../config/config";
import {
  GridStyled,
  LogoStyled,
  OuterGridStyled,
  WriteupContent,
} from "./styles";

export default function HiredlyWriteup(props) {
  const { companyWriteup } = props;

  return (
    <OuterGridStyled
      container
      justifyContent={"center"}
      alignItems={"center"}
      marginBottom={"40px"}
    >
      <GridStyled width={"1440px"}>
        <LogoStyled
          id="hiredly-logo-button"
          draggable={false}
          src={assetDomain + "/images/landing/hiredly-logo.png"}
          alt="hiredly-logo"
        />
        <WriteupContent
          dangerouslySetInnerHTML={{
            __html: companyWriteup?.attributes?.content,
          }}
        ></WriteupContent>
      </GridStyled>
    </OuterGridStyled>
  );
}
