import React from "react";
import { checkAuthentication } from "../../redux/actions/user_action";

function WithResume(WrappedComponent) {
  return (props) => {
    const newProps = {
      ...props,
      isLoggedIn: checkAuthentication(),
    };

    if (true) {
      return <WrappedComponent {...newProps} />;
    } else {
      return null;
    }
  };
}

export default WithResume;
