import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadGeneratedResume } from "../../../../helpers/data_management";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import { updateReplaceResumePopupStatus } from "../../../../redux/actions/user_action";
import { HeightSpacer } from "../../../shared/Spacer/styles";
import {
  ButtonContainer,
  DialogStyled,
  HorizontalRuleStyled,
  ModalContainer,
  OutlinedButton,
  SolidPurpleButton,
  Text,
  TextContainer,
  TitleContainer,
} from "./styles";

function ReplaceExistingResumePopup(props) {
  const {
    fyp,
    currentUser,
    handleCvFileChange,
    getCurrentFile,
    setCurrentFile,
    sectionSeq,
  } = props;

  let visibleSections = sectionSeq?.filter(
    (section) => section.visible === true
  );

  const dispatch = useDispatch();

  const isReplaceResumePopupOpen = useSelector(
    (state) => state.user.isReplaceResumeOpen
  );

  const onClosingPopup = () => {
    sendTrackingEvent({
      event: "CE_replace-resume-no-thanks",
    });
    dispatch(updateReplaceResumePopupStatus(false));

    if (setCurrentFile) setCurrentFile(null);
  };

  const onReplaceResume = () => {
    sendTrackingEvent({
      event: "CE_replace-resume",
    });
    dispatch(updateReplaceResumePopupStatus(false));

    const currentFile = fyp ? getCurrentFile : getCurrentFile();

    if (fyp) {
      if (currentFile) {
        handleCvFileChange(currentFile);
      }
    } else {
      if (currentFile) {
        handleCvFileChange(currentFile);
      } else {
        uploadGeneratedResume(
          currentUser,
          handleCvFileChange,
          visibleSections,
          true
        );
      }
    }

    if (setCurrentFile) setCurrentFile(null);
  };

  return (
    <DialogStyled open={isReplaceResumePopupOpen} onClose={onClosingPopup}>
      <ModalContainer>
        <TitleContainer>
          <Text $text={"title"}>Replace Existing Resume</Text>
        </TitleContainer>
        <HorizontalRuleStyled />
        <HeightSpacer $height={"10px"} />
        <TextContainer>
          <Text $text={"message"}>
            Your existing resume will be replaced. Do you wish to continue?
          </Text>
        </TextContainer>
        <HeightSpacer $height={"20px"} />
        <ButtonContainer>
          <OutlinedButton onClick={onClosingPopup}>No, Thanks</OutlinedButton>
          <SolidPurpleButton onClick={onReplaceResume}>
            Replace Resume
          </SolidPurpleButton>
        </ButtonContainer>
      </ModalContainer>
    </DialogStyled>
  );
}

export default ReplaceExistingResumePopup;
