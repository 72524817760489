import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const OuterGridStyled = styled(Grid)((props) => ({
  backgroundColor: Color.backgroundGrey,
  padding: "0 80px 32px 80px",

  [props.theme.breakpoints.down("lg")]: {
    padding: "0 24px 24px 24px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0 16px 24px 16px",
  },
}));

export const GridStyled = styled(Grid)((props) => ({
  //
}));

export const WriteupTitle = styled("p")((props) => ({
  textTransform: "uppercase",
  fontWeight: "600",
  letterSpacing: "1.5px",
  lineHeight: "20px",
  fontSize: "10px",
  fontFamily: "Inter",
  color: Color.hiredlyPurple,
  margin: "0px",
}));

export const WriteupContent = styled(Grid)((props) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  lineHeight: "20px",
  margin: 0,
}));
