import React from "react";
import { SkeletonContainer } from "./styles";

function JobListSkeleton(props) {
  const { filterParams, pageParams } = props;

  const filterLoads = filterParams !== undefined;
  const pageLoads = pageParams !== undefined;

  const hide = filterLoads && pageLoads;

  return hide ? null : <SkeletonContainer></SkeletonContainer>;
}

export default JobListSkeleton;
