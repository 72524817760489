import { H1Tag, H1Wrapper } from "./styles";

export default function JobListSubheader(props) {
  const { titleInfo } = props;
  const { title, key } = titleInfo ?? {};

  const titleText =
    titleInfo || title
      ? key === "state"
        ? "Jobs in " + title
        : title + " Jobs"
      : "";

  return titleInfo || title ? (
    <H1Wrapper>
      <H1Tag>{titleText}</H1Tag>
    </H1Wrapper>
  ) : null;
}
