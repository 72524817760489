import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";
import * as config from "../../../config/config";

export const Wrapper = styling("div")({
  display: "flex",
  justifyContent: "center",

  margin: "0 auto",
  maxWidth: "1440px",
  padding: "0 5rem 2.5rem",

  "@media (max-width: 1280px)": {
    padding: "0 1.5rem 2.5rem",
  },

  "@media (max-width: 640px)": {
    padding: "0 1rem 2.5rem",
  },
});

export const ForYouWrapper = styling("div")({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",

  margin: "1rem auto 0",
  width: "100%",
});

export const PlaceholderWrapper = styling("div")({
  width: "100%",
  margin: "5rem auto 0",
  padding: "0 1rem",

  "@media (max-width: 640px)": {
    margin: "1.5rem auto 0",
  },
});

export const VisitorWrapper = styling("div")({
  backgroundImage: `url(${config.assetDomain}/images/hiredly/blurred-jlp-desktop.webp)`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  height: "80svh",
  width: "100%",
  maxWidth: "1440px",
  padding: "1.5rem 0",

  "@media (max-width: 1280px)": {
    backgroundImage: `url(${config.assetDomain}/images/hiredly/blurred-jlp-tablet.webp)`,
    maxWidth: "972px",
  },

  "@media (max-width: 640px)": {
    backgroundImage: `url(${config.assetDomain}/images/hiredly/blurred-jlp-mobile.webp)`,
    width: "auto",
    padding: "0 1rem",
  },
});

export const VisitorCard = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  height: "70svh",
  width: "29.375rem",
  margin: "2rem auto 2.5rem",

  "@media (max-width: 640px)": {
    width: "100%",
    margin: "1rem auto 0",
  },
}));

export const ResumeDropzoneCard = styling(Box)((props) => ({
  backgroundColor: Color.white,
  border: `1px solid ${Color.grey}`,
  borderRadius: "0.5rem",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  minHeight: "20.625rem",
  maxWidth: "50rem",
  padding: "1rem 1.5rem",
  margin: "2.5rem auto 0",

  "@media (max-width: 640px)": {
    margin: "1rem auto 0",
  },
}));

export const ButtonStyled = styling(ButtonBase)((props) => ({
  color: props.variant === "filled" ? Color.white : Color.black,
  backgroundColor:
    props.variant === "filled" ? Color.hiredlyPurple : Color.white,
  border:
    props.variant === "filled"
      ? `1px solid ${Color.hiredlyPurple}`
      : `1px solid ${Color.black}`,
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: props.variant === "filled" ? "700" : "500",
  letterSpacing: "0.074px",
  whiteSpace: "nowrap",

  height: "2.625rem",
  width: "12.5rem",

  transition: "all 0.125s ease-in-out",

  "@media (min-width: 1280px)": {
    "&:hover": {
      color: props.variant === "filled" ? Color.white : Color.black,
      backgroundColor:
        props.variant === "filled" ? Color.darkPurple : Color.lightGrey,
      scale: "1.025",
    },
  },

  "@media (max-width: 640px)": {
    height: "2.25rem",
    width: "9.5rem",
  },
}));

export const LinkStyled = styling(ButtonBase)((props) => ({
  color: Color.hiredlyPurple,
  fontSize: "1rem",
  fontWeight: "600",

  height: "2.625rem",
  padding: "10.5px 0 12.5px",
}));

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const SubheadingText = styling("h3")({
  fontSize: "1rem",
  fontWeight: 400,
  lineSpacing: "24px",
  letterSpacing: "0.15px",
  margin: "0",

  "@media (max-width: 1279px)": {
    fontSize: "0.875rem",
    lineSpacing: "20px",
    letterSpacing: "0.112px",
  },
});

export const TextStyled = styling(Typography)({});

export const VisitorTitle = styling(Typography)({
  fontSize: "1.5625rem",
  fontWeight: 500,
  lineHeight: "32px",
  textAlign: "center",
  whiteSpace: "nowrap",

  "@media (max-width: 640px)": {
    fontSize: "1.0625rem",
    lineHeight: "24px",
  },
});

export const VisitorSubtitle = styling(Typography)({
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",

  "@media (max-width: 640px)": {},
});

export const PlaceholderTitle = styling(Typography)({
  color: Color.darkPurple,
  fontSize: "1.5625rem",
  fontWeight: 600,
  lineHeight: "32px",
  textAlign: "center",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "22px",
  },
});

export const PlaceholderSubtitle = styling(Typography)({
  color: Color.black,
  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
    letterSpacing: "0.112px",
    padding: "0 1rem",
  },
});

export const LockIcon = styling(LockClosedIcon)({
  height: "120px",
  width: "120px",

  "@media (max-width: 640px)": {
    height: "60px",
    width: "60px",
  },
});

export const SuccessIcon = styling(CheckCircleIcon)({
  color: Color.buttonGreen,
  height: "20px",
  width: "20px",
});
