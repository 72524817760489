import { BackLinkGridStyled, GridStyled, OuterGridStyled } from "./styles";

export default function BacklinkComponent(props) {
  const { cmsSpecialisations, cmsLocations } = props;

  return (
    <OuterGridStyled
      container
      flexWrap={"wrap"}
      columnGap={"48px"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <GridStyled container maxWidth={"1440px"}>
        <BackLinkGridStyled
          container
          columnGap={"15px"}
          rowGap={"15px"}
          alignContent={"flex-start"}
        >
          <GridStyled container>
            <span>jobs by specialisations</span>
          </GridStyled>
          {cmsSpecialisations?.map((spec) => (
            <a
              href={`/jobs-in-${spec?.attributes.slug}`}
              key={`${spec?.attributes.slug}-button`}
            >
              {spec?.attributes?.name ?? "-"}
            </a>
          ))}
        </BackLinkGridStyled>
        <BackLinkGridStyled
          container
          columnGap={"15px"}
          rowGap={"15px"}
          alignContent={"flex-start"}
        >
          <GridStyled container>
            <span>jobs by location</span>
          </GridStyled>
          {cmsLocations?.map((loc) => (
            <a
              href={`/jobs-in-${loc?.attributes?.slug}`}
              key={`${loc?.attributes?.slug}-button`}
            >
              {loc?.attributes?.location ?? "-"}
            </a>
          ))}
        </BackLinkGridStyled>
      </GridStyled>
    </OuterGridStyled>
  );
}
