import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../../assets/colors";
import resumeProcess from "../../../../assets/lotties/job-list/resume-parsing.json";
import resumeUpload from "../../../../assets/lotties/job-list/resume-uploading.json";
import { convertBase64, setItem } from "../../../../helpers/data_management";
import {
  getAshleyRecommendedJobs,
  updateJobListTab,
} from "../../../../redux/actions/job_action";
import {
  checkResumeParsedStatus,
  newGetUserInfo,
  newResumeUpload,
  updateReplaceResumePopupStatus,
  updateResumeBuildingStatus,
  updateResumeDoneStatus,
  updateResumeProgressPopupStatus,
  updateResumeUploadingStatus,
  updateUser,
} from "../../../../redux/actions/user_action";
import * as types from "../../../../redux/types/user_type";
import ReplaceExistingResumePopup from "../../../profile/ResumePopups/ReplaceExistingResume/ReplaceExistingResume";
import ResumeProgressIndicatorPopup from "../../../profile/ResumePopups/ResumeProgressIndicator/ResumeProgressIndicator";
import { BoxContainer, SuccessIcon, TextStyled } from "../styles";
import {
  BrowseButton,
  ContextText,
  DragResumeElement,
  ErrorContainer,
  ErrorIcon,
  ExploreOutlinedButton,
  InputStyled,
  InvalidText,
  LinkStyled,
  ProcessingContainer,
  ResumeFailed,
  ReuploadButton,
  UploadContainer,
  Wrapper,
} from "./styles";

function ResumeDropzone(props) {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width: 640px)");

  const jobs = useSelector((state) => state.jobs?.jobs);
  const user = useSelector((state) => state.user?.user);

  const resumeExist = user?.resume?.length > 1;
  const resumeStatus = user?.state;

  const [dragActive, setDragActive] = useState(false);
  const [validResume, setValidResume] = useState(true);
  const [invalidSize, setInvalidSize] = useState(false);
  const [failed, setFailed] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const resumeInvalid =
    failed ||
    (resumeStatus === "approved" && jobs?.length === 0) ||
    resumeStatus === "rejected"
      ? true
      : false;

  const inputRef = useRef(null);

  function handleBrowseFiles() {
    inputRef.current.click();
  }

  // handle drag events
  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      if (!dragActive) {
        setDragActive(true);
      }
    } else if (e.type === "dragleave") {
      if (dragActive) {
        setDragActive(false);
      }
    }
  }

  // triggers when file is dropped
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    if (dragActive) {
      setDragActive(false);
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (user?.state == "no_resume") {
        handleCvFileChange(e.dataTransfer.files[0]);
        e.target.value = null;
      } else {
        preHandleFileChange(e.dataTransfer.files[0], e).then(() => {
          e.target.value = null;
        });
      }
    }
  }

  function handleExploreJobs() {
    dispatch(updateJobListTab(1));
  }

  function handleReupload() {
    inputRef.current.click();

    if (currentFile) {
      handleCvFileChange(currentFile);
    }

    if (setCurrentFile) setCurrentFile(null);
  }

  async function preHandleFileChange(file, event) {
    setCurrentFile(file);
    dispatch(updateReplaceResumePopupStatus(true));
  }

  // triggers when file is selected with click
  function handleFileChange(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (resumeStatus == "no_resume" && !resumeExist) {
        handleCvFileChange(e.target.files[0]);
        e.target.value = null;
      } else {
        preHandleFileChange(e.target.files[0], e);
      }
    }
  }

  async function handleCvFileChange(file, autoGenerated = false) {
    if (!file) return;

    if ((file.size / 1024 / 1024).toFixed(4) > 2) {
      setValidResume(false);
      setInvalidSize(true);
      return;
    }

    if (
      !(
        file.name?.includes(".docx") ||
        file.name?.includes(".doc") ||
        file.name?.includes(".pdf")
      )
    ) {
      setValidResume(false);
      setInvalidSize(false);
      return;
    }

    // Converting PDF file to base64
    convertBase64(file).then((cvFile) => {
      // Trigger graphQL to upload cvFile
      let params = {
        resume: cvFile,
        canCancel: autoGenerated,
        origin: "profile",
        user_resume: user.resume,
        autoGenerated: autoGenerated,
        state: autoGenerated ? "approved" : "review",
      };

      // Show progress popup
      dispatch(updateResumeProgressPopupStatus(true));

      // Hide building
      dispatch(updateResumeBuildingStatus(false));

      // Show uploading
      dispatch(updateResumeUploadingStatus(true));

      dispatch(newResumeUpload(params)).then((response) => {
        // Hide uploading
        dispatch(updateResumeUploadingStatus(false));

        if (response.type === types.UPDATE_USER_CV_SUCCEED) {
          // Show done then hide done after 2 seconds
          dispatch(updateResumeDoneStatus(true));
          setTimeout(() => {
            dispatch(updateResumeProgressPopupStatus(false));
            dispatch(updateResumeDoneStatus(false));
          }, 2000);

          // Update redux user information
          dispatch(updateUser(response.user));

          // Refetch user info after 5 minutes
          setTimeout(() => {
            refetchUserInfo(response.user);
          }, 300500);

          // Update local information
          setItem(types.USER_PROFILE, JSON.stringify(response.user));
        } else {
          setFailed(true);
        }
      });
    });
  }

  function refetchUserInfo(user) {
    const inputTime = dayjs(user?.uploadResumeAt);
    const currentTime = dayjs();
    const timePlusFiveMinutes = inputTime.add(5, "minute");

    if (timePlusFiveMinutes.isAfter(currentTime)) {
      dispatch(checkResumeParsedStatus()).then((response) => {
        if (response.type === types.CHECK_RESUME_PARSED_STATUS_SUCCEED) {
          dispatch(newGetUserInfo()).then(() => {
            dispatch(getAshleyRecommendedJobs(params));
          });
        } else {
          setFailed(true);
        }
      });
    }
  }

  return (
    <Wrapper sizeChange={resumeExist}>
      {resumeExist ? (
        resumeInvalid ? (
          <BoxContainer gap={"0.5rem"} alignItems={"center"}>
            <TextStyled
              color={Color.darkPurple}
              fontSize={isMobile ? "1rem" : "1.25rem"}
              fontWeight={"500"}
              lineHeight={"24px"}
            >
              No available matches currently
            </TextStyled>
          </BoxContainer>
        ) : (
          <BoxContainer gap={"0.5rem"} alignItems={"center"}>
            <SuccessIcon />
            <TextStyled
              fontSize={"1rem"}
              fontWeight={"500"}
              lineHeight={"24px"}
            >
              Resume upload successful!
            </TextStyled>
          </BoxContainer>
        )
      ) : (
        <BoxContainer flexDirection={"column"} alignItems={"center"}>
          <TextStyled
            fontSize={isMobile ? "1rem" : "1.25rem"}
            fontWeight={"600"}
            lineHeight={"24px"}
          >
            Get better matches!
          </TextStyled>
          <TextStyled
            fontSize={isMobile ? "0.875rem" : "1rem"}
            lineHeight={"24px"}
            letterSpacing={"0.15px"}
          >
            Upload your resume:
          </TextStyled>
        </BoxContainer>
      )}

      {resumeExist ? (
        resumeInvalid ? (
          <ProcessingContainer>
            <ResumeFailed />
            <ContextText $nopadding>
              Unfortunately, we couldn't find any jobs currently that matches
              the content in your uploaded resume. Try re-uploading your resume
              in a different format.
            </ContextText>
            <TextStyled
              color={"rgba(0, 0, 0, 0.60)"}
              fontSize={isMobile ? "0.625rem" : "0.75rem"}
              lineHeight={isMobile ? "24px" : "20px"}
              textAlign={"center"}
              fontStyle={"italic"}
            >
              If the problem persists, contact us at{" "}
              <LinkStyled
                target="_blank"
                rel="noopener"
                href="mailto:hello@hiredly.com?subject=No Job Recommendations Available&body=Dear Hiredly,<br><br>I am unable to view any job recommendations.<br><br>Please could you assist me with:"
              >
                hello@hiredly.com
              </LinkStyled>
            </TextStyled>
          </ProcessingContainer>
        ) : (
          <ProcessingContainer>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: resumeProcess,
              }}
              width={"100px"}
            />
            <ContextText>
              Give us a minute to process your resume for the best possible job
              matches. In the meantime, feel free to discover available jobs on
              our platform.
            </ContextText>
          </ProcessingContainer>
        )
      ) : (
        <UploadContainer onDragEnter={handleDrag}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: resumeUpload,
            }}
            width={"104px"}
          />
          <BoxContainer
            flexDirection={"column"}
            alignItems={"center"}
            gap={"0.75rem"}
            width={"100%"}
          >
            <BrowseButton onClick={handleBrowseFiles}>
              Browse files
            </BrowseButton>
            <TextStyled
              color={"rgba(0, 0, 0, 0.60)"}
              fontSize={"0.75rem"}
              lineHeight={"20px"}
            >
              Support file type: .pdf, doc, .docx
            </TextStyled>
            {!validResume && (
              <ErrorContainer>
                <ErrorIcon />
                <InvalidText>
                  {invalidSize
                    ? "File is too large. Maximum file size is 2MB."
                    : "Please upload in pdf, doc, or docx format"}
                </InvalidText>
              </ErrorContainer>
            )}
          </BoxContainer>
          {dragActive && (
            <DragResumeElement
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </UploadContainer>
      )}

      <InputStyled
        ref={inputRef}
        accept=".pdf,.docx,.doc"
        type="file"
        onChange={handleFileChange}
      />

      {resumeInvalid ? (
        <BoxContainer
          gap={isMobile ? "0.5rem" : "1rem"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <ExploreOutlinedButton onClick={handleExploreJobs}>
            Explore All Jobs
          </ExploreOutlinedButton>
          <ReuploadButton onClick={handleReupload}>
            Reupload Resume
          </ReuploadButton>
        </BoxContainer>
      ) : (
        <ExploreOutlinedButton onClick={handleExploreJobs}>
          Explore All Jobs
        </ExploreOutlinedButton>
      )}
      <ResumeProgressIndicatorPopup />
      <ReplaceExistingResumePopup
        fyp
        currentUser={user}
        getCurrentFile={currentFile}
        setCurrentFile={setCurrentFile}
        handleCvFileChange={handleCvFileChange}
      />
    </Wrapper>
  );
}

export default ResumeDropzone;
