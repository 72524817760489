import {
  GridStyled,
  OuterGridStyled,
  WriteupContent,
  WriteupTitle,
} from "./styles";

export default function SpecialisationWriteup(props) {
  const { specialisation } = props || {};

  const { name, writeup } = specialisation?.attributes || {};

  return name && writeup ? (
    <OuterGridStyled
      container
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      <GridStyled width={"1440px"}>
        <WriteupTitle>{name ?? "SPECIALISATION NAME"}</WriteupTitle>
        <WriteupContent
          dangerouslySetInnerHTML={{
            __html: writeup ?? "SPECIALISATION WRITEUP",
          }}
        />
      </GridStyled>
    </OuterGridStyled>
  ) : null;
}
