import ExclaimationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../../assets/colors";
import UploadFailIcon from "../../../../assets/svg/resume-rejected.svg";

export const Wrapper = styling(Box)((props) => ({
  backgroundColor: Color.white,
  border: `1px solid ${Color.grey}`,
  borderRadius: "0.5rem",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: props.sizeChange ? "2rem" : "1rem",

  minHeight: "20.625rem",
  maxWidth: "50rem",
  padding: props.sizeChange ? "2rem 1.5rem" : "1rem 1.5rem",
  margin: "2.5rem auto 0",

  "@media (max-width: 640px)": {
    margin: "1rem auto 0",
    padding: "1.25rem 1rem 1rem",
  },
}));

export const UploadContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",

  backgroundColor: Color.lightGrey,
  borderRadius: "0.625rem",
  border: `1px dashed ${Color.darkGrey}`,

  height: "100%",
  // maxHeight: "11.25rem",
  width: "100%",
  padding: "0.5rem 1rem 1rem",

  "@media (max-width: 640px)": {
    padding: "0.625rem",
  },
});

export const ProcessingContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.375rem",

  "@media (max-width: 640px)": {
    gap: "1rem",
  },
});

export const ErrorContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.25rem",

  backgroundColor: "rgba(216, 116, 116, 0.10)",
  border: `1px solid ${Color.error}`,
  borderRadius: "0.5rem",

  padding: "0.375rem 0.25rem",

  "@media (max-width: 640px)": {
    width: "fit-content",
  },
}));

export const ContextText = styling(Typography)((props) => ({
  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",

  padding: "0 8rem",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "20px",
    letterSpacing: "0.112px",

    padding: props.$nopadding ? "0" : "0 1.5rem",
  },
}));

export const InvalidText = styling(Typography)((props) => ({
  color: Color.black,
  fontSize: "0.75rem",
  fontStyle: "italic",
  lineHeight: "20px",
}));

export const LinkStyled = styling("a")({
  color: Color.hiredlyPurple,
});

export const InputStyled = styling("input")({
  display: "none",
});

export const DragResumeElement = styling("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
  zIndex: 2,
});

export const BrowseButton = styling(ButtonBase)((props) => ({
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${props.color}`,
  borderRadius: "6.25rem",

  fontSize: "0.875rem",
  fontWeight: "700",

  height: "2.25rem",
  width: "10rem",
  padding: "0 1rem",

  "@media (max-width: 640px)": {
    width: "100%",
  },
}));

export const ReuploadButton = styling(ButtonBase)((props) => ({
  color: Color.white,
  backgroundColor: Color.black,
  border: `1px solid ${Color.black}`,
  borderRadius: "6.25rem",

  fontSize: "1rem",
  fontWeight: "700",

  height: "2.625rem",
  width: "12.5rem",
  padding: "0 1rem",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    whiteSpace: "nowrap",
    height: "1.875rem",
    width: "100%",
  },
}));

export const ExploreOutlinedButton = styling(ButtonBase)((props) => ({
  color: Color.hiredlyPurple,
  backgroundColor: Color.white,
  border: `1px solid ${Color.hiredlyPurple}`,
  borderRadius: "6.25rem",

  fontSize: "1rem",
  fontWeight: "500",

  height: "2.625rem",
  width: "12.5rem",
  padding: "0 1rem",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    whiteSpace: "nowrap",
    height: "1.875rem",
    width: "100%",
  },
}));

export const ResumeFailed = styling(UploadFailIcon)({
  height: "100px",
  width: "100px",
});

export const ErrorIcon = styling(ExclaimationCircleIcon)({
  color: Color.error,
  height: "18px",
  width: "18px",
});
