import { styled as styling } from "@mui/material/styles";

export const H1Wrapper = styling("div")({
  position: "absolute",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

export const H1Tag = styling("h1")({
  margin: "0",
  padding: "0",
  height: "1px",
  width: "1px",
  clip: "rect(1px, 1px, 1px, 1px)",
});
